














import { Component, Vue } from "vue-property-decorator";
import MemberCard from "./MemberCard.vue";

@Component({
  components: {
    MemberCard
  }
})
export default class ScholarshipAppLayout extends Vue {}
