



































































import { Component, Vue } from "vue-property-decorator";
import firebase from "@/plugins/firebase";
import "@/types";

interface Application {
  id: string;
  dateCreated: Date;
  status: "accepted" | "rejected" | "inprogress" | "inevaluation" | "missing";
  scholarship: {
    id: string;
    title: string;
    urlCode: string;
  };
}

@Component
export default class MemberCard extends Vue {
  get member() {
    return this.$store.getters["scholarship/member"];
  }

  get memberSignedIn() {
    return this.$store.getters["scholarship/memberSignedIn"];
  }

  signOut() {
    this.$store.dispatch("scholarship/signOut");
    this.$router.push({ name: "scholarship-signin" });
  }

  pastApplications: Application[] = [] as Application[];

  async getPastApplications() {
    this.pastApplications = [] as Application[];

    const appsQs = await firebase
      .firestore()
      .collection("/apps/kahev-org/scholarshipApps")
      .where("member.email", "==", this.member.email)
      .get();

    if (!appsQs.empty) {
      appsQs.docs.forEach(async appDoc => {
        const scholarshipDoc = await firebase
          .firestore()
          .doc(`/apps/kahev-org/scholarships/${appDoc.data().scholarshipId}`)
          .get();

        this.pastApplications.push({
          id: appDoc.id,
          dateCreated: appDoc.data().dateCreated.toDate(),
          status: appDoc.data().status,
          scholarship: {
            id: scholarshipDoc.id,
            title: scholarshipDoc.data()?.title,
            urlCode: scholarshipDoc.data()?.urlCode
          }
        });
      });
    }
  }

  mounted() {
    if (!this.memberSignedIn) {
      this.$router.push({ name: "scholarship-signin" });
    }

    this.getPastApplications();
  }
}
